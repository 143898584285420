<template>
  <div>
    <p>
      Need help? Use the guide
      <bid-link
        :router-link="false"
        href-link="https://academy.bidbrain.com/how-to-add-your-product-feed-to-bidbrain"
        target="_blank"
        primary
      >
        <template #text> How to add product feed </template>
      </bid-link>
      or read our
      <bid-link
        :router-link="false"
        href-link="https://academy.bidbrain.com/feed-requriremnts"
        target="_blank"
        primary
      >
        <template #text> Feed requirements. </template>
      </bid-link>
    </p>
    <div class="flex flex-col mb-6">
      <span class="flex flex-row gap-2">
        <ic-check class="text-bb-dull-green" />
        The feed should meet the minimal requirements
      </span>
      <span class="flex flex-row gap-2">
        <ic-check class="text-bb-dull-green" />
        XML or CSV format
      </span>
      <span class="flex flex-row gap-2">
        <ic-check class="text-bb-dull-green" />
        Feed should not be available through public URL
      </span>
      <span class="flex flex-row gap-2">
        <ic-check class="text-bb-dull-green" />
        Google Shopping format
      </span>
    </div>
    <p
      v-if="insideOnboarding"
      class=""
    >
      <span class="font-bold">Dont have a feed?&nbsp;</span>
      <span
        @click.stop="skipFeedStep"
        class="underline cursor-pointer"
        >Skip this step</span
      >
      and we will use your product data from Merchant center instead.
    </p>
    <form @keydown.prevent.enter="submit">
      <div class="input-cols">
        <input-text
          label="Feed name *"
          v-model.trim="form.name"
          :input-id="instanceId('name')"
          input-name="feed[name]"
          :disabled="loading"
          @focusout="$v.form.name.$touch"
          :success="$v.form.name.$dirty && !$v.form.name.$error"
          :error-message="$v.form.name.$error ? 'Please enter a name' : null"
        />

        <input-text
          label="Feed url *"
          v-model.trim="form.url"
          :input-id="instanceId('url')"
          input-name="feed[url]"
          :disabled="loading"
          @focusout="$v.form.url.$touch"
          :success="$v.form.url.$dirty && !$v.form.url.$error"
          :error-message="
            $v.form.url.$error
              ? 'Please enter a store url in the following format: https://domain.com/feed-path.xml'
              : null
          "
        />

        <language-picker
          label="Feed language *"
          v-model="selectedLanguage"
          :languages="languageArray"
          :disabled="loading || !countryId"
          @focusout="$v.selectedLanguage.$touch"
          :success="$v.selectedLanguage.$dirty && !$v.selectedLanguage.$error"
          :error-message="$v.selectedLanguage.$error ? 'Please select language' : null"
          :input-id="instanceId('language')"
          input-name="feed[language]"
        />
      </div>
    </form>

    <transition name="fade">
      <div v-if="!countryId">
        <feedback-message message="You must select store country first!" />
        <feedback-message
          type="info"
          message="So we know what feed languages will be available."
        />
        <router-link
          :to="{ name: 'store:settings:store' }"
          class="flex items-center space-x-2 mb-2"
        >
          <span>Store settings</span>
          <ic-arrow size="16" />
        </router-link>
      </div>
    </transition>

    <slot
      :submit="submit"
      :clear="clear"
      :loading="loading"
      :countryId="countryId"
      name="action-btn"
    />
  </div>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators'
import InputText from '@/components/input/InputText'
import { newReference, normalize } from '@/utils/util'
import LanguagePicker from '@/components/input/LanguagePicker'
import * as Sentry from '@sentry/vue'
import { mapActions, mapGetters } from 'vuex'
import IcArrow from '@/components/icon/ic-arrow'
import FeedbackMessage from '@/components/base/FeedbackMessage'
import BidLink from '@/components/base/BidLink'
import Toast from '@/components/shared/Toast'
import IcCheck from 'vue-material-design-icons/Check'

const EMPTY_FORM = {
  name: null,
  url: null,
  language_id: null,
}

export default {
  name: 'feed-form',
  components: { BidLink, FeedbackMessage, IcArrow, LanguagePicker, InputText, Toast, IcCheck },
  props: {
    loadFeeds: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['submitted', 'skipped'],
  data() {
    return {
      loadingLanguages: false,
      creatingFeed: false,
      createError: null,
      languages: {},
      form: newReference(EMPTY_FORM),
      showErrorDetails: false,
      selectedLanguage: null,
    }
  },
  validations: {
    selectedLanguage: { required },
    form: {
      name: { required },
      url: { required, url },
      language_id: { required },
    },
  },
  computed: {
    ...mapGetters({
      store: 'store/selectedStore',
      loadingFeedsById: 'feed/isFeedsLoadingByStoreId',
      feedsByStore: 'feed/getFeedsByStoreId',
    }),
    loading() {
      return this.loadingLanguages || this.feedsLoading || this.creatingFeed
    },
    feedsLoading() {
      return this.store ? this.loadingFeedsById(this.store.id) : false
    },
    feeds() {
      return this.store ? this.feedsByStore(this.store.id) : []
    },
    countryId() {
      return this.store?.country_id || null
    },
    languageArray() {
      return Object.values(this.languages)
    },
    insideOnboarding() {
      return this.$route.name == 'dashboard'
    },
  },
  watch: {
    store() {
      this.loadData()
    },
    selectedLanguage(language) {
      this.form.language_id = language ? language.id : null
    },
  },
  methods: {
    ...mapActions({
      loadFeedsByStore: 'feed/loadFeedsByStoreId',
      loadFeedsIfNeededByStore: 'feed/loadFeedsByStoreIdIfNeeded',
    }),
    loadData() {
      if (!!this.store) {
        this.clear()
        this.loadLanguages()
        if (this.loadFeeds) {
          this.loadFeedsIfNeededByStore(this.store.id)
        }
      }
    },
    loadLanguages() {
      this.languages = {}
      if (!this.countryId) {
        return
      }

      this.loadingLanguagesError = null
      this.loadingLanguages = true

      return axios
        .get(`country/${this.countryId}/language`)
        .then(res => (this.languages = normalize(res.data.data)))
        .catch(e => {
          Sentry.captureException(e, { tags: { country_id: this.countryId } })
          this.loadingLanguagesError = e.message
        })
        .finally(() => (this.loadingLanguages = false))
    },
    instanceId(uniqueName) {
      return `${this._uid}:feed:${uniqueName}`
    },
    clear() {
      this.form.name = null
      this.form.url = null
      this.form.language_id = null
      this.selectedLanguage = null
      this.$v.$reset()
    },
    feedAlreadyExist() {
      const feedUrl = this.form.url.toLowerCase()
      return !!this.feeds.find(feed => feed.url === feedUrl)
    },
    submit() {
      this.$v.form.$touch()
      this.$v.selectedLanguage.$touch()
      if (this.$v.$invalid) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Fields missing',
            type: 'error',
          },
        })
        return false
      }
      if (this.feedAlreadyExist()) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Feed url already added',
            type: 'error',
          },
        })
        return false
      }

      this.creatingFeed = true
      this.createError = null

      return this.$http
        .post(`/store/${this.store.id}/feed`, newReference(this.form))
        .then(() => {
          this.$toast.success({
            component: Toast,
            props: {
              title: 'Success',
              message: 'Well done! You added a new product feed!',
              type: 'success',
            },
          })

          this.clear()
          this.$emit('submitted')
          if (this.loadFeeds) {
            this.loadFeedsByStore(this.store.id)
          }
        })
        .catch(e => {
          this.createError = e
          if (this.createError.response.status == '422') {
            this.$toast.error({
              component: Toast,
              props: {
                title: 'Error',
                message: 'That url does not contain a feed',
                type: 'error',
              },
            })
          } else {
            this.$toast.error({
              component: Toast,
              props: {
                title: 'Error',
                message: 'Could not add feed',
                type: 'error',
              },
            })
          }
          Sentry.captureException(e, { tags: { store_id: this.store.id } })
        })
        .finally(() => {
          this.creatingFeed = false
        })
    },
    skipFeedStep() {
      this.$emit('skipped')
    },
  },
  beforeMount() {
    this.loadData()
  },
}
</script>
<style lang="scss" scoped>
.input-cols {
  @apply grid gap-y-1;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
