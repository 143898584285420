<template>
  <base-modal>
    <template #header>
      <h3 class="mb-10 -mt-4">{{ name }}</h3>
    </template>

    <template #default>
      <input-text
        class="w-1/2 mx-auto"
        v-model.trim="query"
        input-id="extendedDataQuery"
        input-name="query"
        :disabled="loading"
      >
        <template #prefix>
          <ic-filter
            :size="24"
            class="mb-1"
          />
        </template>
      </input-text>

      <div
        class="overflow-y-scroll"
        style="height: 400px"
      >
        <table class="table-auto w-full text-left">
          <caption class="hidden">
            Check details
          </caption>
          <thead>
            <tr v-if="data.length > 0 && filteredData.length > 0">
              <th
                v-for="prop in header"
                v-if="prop !== 'querable'"
                :key="prop"
                scope="col"
              >
                {{ prop }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="data.length > 0 && filteredData.length === 0">
              <td style="width: 100%">
                <div class="italic">No match for "{{ query }}"!</div>
                <button
                  @click="query = ''"
                  class="link-primary"
                >
                  clear filter?
                </button>
              </td>
            </tr>
            <tr
              v-for="item in filteredData"
              :key="item.id"
              class="border-b"
            >
              <td
                v-for="prop in header"
                v-if="prop !== 'querable'"
                :key="prop.id"
              >
                <p
                  class="my-auto py-2"
                  v-if="prop != 'link'"
                >
                  {{ item[prop] }}
                </p>
                <a
                  class="my-auto"
                  v-if="prop === 'link'"
                  :href="item.link"
                  >{{ item[prop] }}</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </base-modal>
</template>

<script>
import InputText from '@/components/input/InputText'
import IcFilter from '../icon/ic-filter.vue'

export default {
  name: 'check-item-extended-data',
  components: { InputText, IcFilter },
  props: {
    url: {
      type: String,
    },
    name: {
      type: String,
    },
  },
  modalProps: {
    height: 800,
    maxWidth: 1100,
    scrollable: true,
  },
  data() {
    return {
      data: [],
      loading: true,
      error: null,
      query: '',
    }
  },
  computed: {
    header() {
      if (this.data.length > 0) {
        return Object.keys(this.data[0])
      }
    },
    filteredData() {
      if (!this.query) {
        return this.data
      }
      this.query = this.query.toLowerCase()
      return this.data.filter(item => item.querable.includes(this.query))
    },
  },
  methods: {
    loadExtendedData() {
      if (!this.url) return

      this.loading = true
      this.error = null

      this.$http
        .get(this.url)
        .then(({ data }) => {
          this.data = data.data.map(item => {
            item.querable = Object.values(item).join(' ').toLowerCase()
            return item
          })
        })
        .catch(e => (this.error = e))
        .finally(() => (this.loading = false))
    },
  },
  mounted() {
    this.loadExtendedData()
  },
}
</script>
