<template>
  <div class="space-y-8 p2">
    <div>
      <h3>Logging on:</h3>
      <ol>
        <li>
          Log into your Merchant Center account
          <a
            href="https://merchants.google.com/"
            target="_blank"
            rel="noopener"
            class="ml-2"
          >
            <ic-open-window />
          </a>
        </li>
        <li>On the left side menu click on <span class="text-bb-neon-purple">‘Products’</span></li>
        <li>Click on <span class="text-bb-neon-purple"> ‘Feeds’</span></li>
        <li>Click on <span class="text-bb-neon-purple">‘Add supplemental feed’</span></li>
        <li>
          Enter your store name and country for the supplemental feed. It must be unique.
          <br />
          ex: ‘Bidbrain MyStoreName SE supplemental feed’
        </li>
        <li>Select <span class="text-bb-neon-purple">‘Scheduled fetch’</span>and continue</li>
      </ol>
    </div>

    <div>
      <h3>Creating a feed:</h3>
      <ol>
        <li>
          Enter a file name for the supplemental feed, we recommend using the same name as before
          <br />ex: ‘Bidbrain MyStoreName SE supplemental feed’
        </li>
        <li>Under Fetch frequency, select <span class="text-bb-neon-purple">‘Daily’</span></li>
        <li>
          Select a time that the supplemental feed will be fetched each day under “Fetch time”. It’s recommended to
          select a time 1-2 hours after the primary feed usually gets updated.
        </li>
        <li>
          <div class="w-full">
            Enter the following file url:
            <div class="flex">
              <div class="bg-bb-pale-grey p-3 overflow-x-auto">
                {{ supplementalFeedAddress }}
              </div>
              <div
                @click="copy"
                class="flex items-center link-primary no-underline ml-4"
              >
                <component
                  :is="copyIcon"
                  class="mr-2"
                />
                Copy
              </div>
            </div>
          </div>
        </li>
        <li>Leave the <span class="text-bb-neon-purple">Password</span> blank, and continue</li>
      </ol>
    </div>

    <div>
      <h3>Adding to primary feed:</h3>
      <ol>
        <li>Select the primary feed that you want to connect the supplemental feed to</li>
        <li>Select <span class="text-bb-neon-purple">‘Create feed’</span></li>
      </ol>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import IcCopy from 'vue-material-design-icons/ContentCopy'
import IcOpenWindow from 'vue-material-design-icons/OpenInNew'
import IcCheck from 'vue-material-design-icons/Check'

export default {
  name: 'supplemental-feed-guide',
  components: { IcCheck, IcCopy, IcOpenWindow },
  data() {
    return {
      copyIcon: 'ic-copy',
    }
  },
  computed: {
    ...mapGetters({
      store: 'store/selectedStore',
    }),
    supplementalFeedAddress() {
      return this.store
        ? `https://feeds.bidbrain.com/supplemental/${this.store.supplemental_id}.xml`
        : 'N/A (no store selected)'
    },
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.supplementalFeedAddress)
      this.copyIcon = 'ic-check'
      setTimeout(() => (this.copyIcon = 'ic-copy'), 3000)
    },
  },
}
</script>
<style lang="scss" scoped>
@import 'src/assets/style/components/document';

li {
  display: flex !important;
  align-items: center;
  position: relative;
  margin-left: 24px;

  &:before {
    position: absolute;
    top: 0;
    left: -24px;
  }
}

li > span {
  @apply mx-1;
}

h3 {
  @apply mb-4;
}
</style>
