<template>
  <div>
    <table :aria-describedby="`Feeds added to ${store ? store.name : ''}`">
      <tr>
        <th
          class="border-none"
          scope="col"
        >
          Feed name
        </th>
        <th
          class="border-none"
          scope="col"
        >
          Language
        </th>
        <th
          class="border-none"
          scope="col"
        >
          URL
        </th>
        <th
          class="border-none"
          scope="col"
        >
          Quality score
        </th>
      </tr>
      <tr v-if="!store">
        <td colspan="4">
          <div class="space-y-4">
            <feedback-message
              type="info"
              message="Please select or add store"
            />
            <the-button
              @click="showStoreConnect"
              primary
            >
              <template #default> Connect store </template>
              <template #icon>
                <ic-plus :size="16" />
              </template>
            </the-button>
          </div>
        </td>
      </tr>
      <tr v-else-if="loadingFeeds">
        <td colspan="4">
          <bb-loader />
        </td>
      </tr>
      <tr v-else-if="feeds.length === 0">
        <td colspan="4">
          <feedback-message
            type="info"
            message="No feeds added."
          />
        </td>
      </tr>
      <tbody
        v-else
        v-for="feed in feeds"
        class="even:bg-white odd:bg-bb-light-grey"
        :key="feed.id"
      >
        <tr
          :key="`row-${feed.id}`"
          @click="toggleExtendedData(feed)"
          :class="{
            'font-bold': isExtended(feed.id),
            'cursor-pointer': !isFeedTooBig(feed),
          }"
          class="border-t"
          :title="feed.alt_text"
        >
          <td class="border-none">{{ feed.name || '-' }}</td>
          <td class="border-none">{{ feed.language.name }}</td>
          <td
            class="truncate max-w-xxs xl:max-w-xl border-none"
            :title="`${feed.url}`"
          >
            {{ feed.url }}
          </td>
          <td
            v-if="isFeedTooBig(feed)"
            class="border-none flex flex-row gap-2"
          >
            <p class="text-bb-dull-red my-auto">Feed is too big</p>
            <the-button
              @click="showSubscriptionModal"
              :disabled="loadingFeeds || !store"
              secondary
              small
            >
              Upgrade
            </the-button>
          </td>
          <td
            v-else-if="!isFeedRefined(feed) && feed.active == true"
            class="text-bb-dull-orange border-none flex flex-row gap-2"
          >
            <ic-info />
            We are downloading your products
          </td>
          <td
            v-else-if="feed.active == false"
            class="text-bb-dull-orange border-none flex flex-row gap-2"
          >
            <ic-info class="my-auto" />
            <div class="flex flex-col">
              <p class="my-auto">Feed is inactive</p>
              <p class="p4 my-auto normal-case">Show more info</p>
            </div>
            <span @click="showDeleteFeedModal(feed)">
              <ic-bin class="mt-1 cursor-pointer" />
            </span>
          </td>
          <td
            v-else
            class="border-none flex flex-row gap-2"
          >
            <ic-cloud
              v-if="scoreText(feed.id) === 'Get score'"
              :size="24"
            />
            {{ scoreText(feed.id) }}
          </td>
        </tr>
        <tr
          v-if="isExtended(feed.id) && !isFeedTooBig(feed)"
          :class="{ hidden: !isExtended(feed.id) }"
          :key="feed.id"
        >
          <td
            colspan="4"
            class="border-none"
          >
            <feed-checker
              v-if="feed.active === true"
              :feedId="feed.id"
            />
            <div v-if="feedErrorList">
              <div
                v-for="feedError in feedErrorList"
                :key="feedError.id"
                class="flex flex-row"
              >
                <p>{{ readableDate(feedError.created_at) }} - {{ feedError.description }}</p>
                <p v-if="feedError.event_name == 'PAUSED' && feedError.meta">
                  for being offline for more than {{ feedError.meta.offlineTimeUntilInactive / 3600 }} hours
                </p>
              </div>
              <the-button
                @click="reactivateFeed(feed.id)"
                secondary
                large
              >
                Reactivate feed
              </the-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="flex justify-center w-full mt-8">
      <the-button
        @click="showFeedForm"
        :disabled="loadingFeeds || !store"
        secondary
        medium
      >
        <template #default> Add new feed </template>
        <template #icon>
          <ic-plus :size="16" />
        </template>
      </the-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import FeedbackMessage from '@/components/base/FeedbackMessage'
import CreateFeedModal from '@/components/modals/CreateFeedModal'
import ConnectStoreModal from '@/components/modals/ConnectStoreModal'
import FeedChecker from '@/components/feed/FeedChecker'
import BbLoader from '@/components/loader/BBLoader'
import IcCloud from '@/components/icon/ic-cloud.vue'
import SubscriptionSelectionModal from '@/components/modals/SubscriptionSelectionModal'
import BaseAlert from '@/components/alert/BaseAlert'
import IcInfo from 'vue-material-design-icons/Information'
import DeleteFeedModal from '@/components/modals/DeleteFeedModal'
import IcBin from '@/components/icon/ic-bin.vue'
import dayjs from 'dayjs'
import Toast from '@/components/shared/Toast'

export default {
  name: 'feed-list',
  components: { BaseAlert, BbLoader, FeedChecker, FeedbackMessage, IcCloud, IcInfo, DeleteFeedModal, IcBin, Toast },
  data() {
    return {
      newFeedUrl: '',
      fetchingFeeds: false,
      extendedRows: {},
      feedErrorList: null,
    }
  },
  computed: {
    ...mapGetters({
      store: 'store/selectedStore',
      feedsByStoreId: 'feed/getFeedsByStoreId',
      feedsLoadingByStoreId: 'feed/isFeedsLoadingByStoreId',
      lastCheckByFeedId: 'check/lastByFeedId',
    }),
    feeds() {
      if (!this.store) return []
      return this.feedsByStoreId(this.store.id)
    },
    loadingFeeds() {
      if (!this.store) return false
      return this.feedsLoadingByStoreId(this.store.id)
    },
  },

  methods: {
    ...mapActions({
      loadFeedsIfNeeded: 'feed/loadFeedsByStoreIdIfNeeded',
      loadFeeds: 'feed/loadFeedsByStoreId',
    }),
    showFeedForm() {
      this.$modal.show(CreateFeedModal)
    },
    showStoreConnect() {
      this.$modal.show(ConnectStoreModal, null, ConnectStoreModal.modalProps)
    },
    toggleExtendedData(feed) {
      if (feed.active === false) {
        this.feedHasErrors(feed.id)
      }
      let feedId = feed.id
      if (!this.isFeedTooBig(feed)) {
        this.extendedRows = {
          ...this.extendedRows,
          [feedId]: !this.extendedRows[feedId],
        }
      }
    },
    isExtended(feedId) {
      return !!this.extendedRows[feedId]
    },
    scoreText(feedId) {
      let lastCheck = this.lastCheckByFeedId(feedId)
      if (!lastCheck) {
        return 'Get score'
      }
      if (!!lastCheck && !!lastCheck.score) {
        return lastCheck.score.previous
          ? `${lastCheck.score.latest} (${lastCheck.score.previous})`
          : lastCheck.score.latest
      }
    },
    isFeedTooBig(feed) {
      if (this.store.subscription_slug === 'free') {
        if (feed?.logs) {
          return (
            !!feed.logs.too_many_products_for_subscription && feed.logs.too_many_products_for_subscription.length > 0
          )
        }
      }
    },
    isFeedRefined(feed) {
      return this.store.feeds.refined_ids.includes(feed.id)
    },
    showSubscriptionModal() {
      this.$modal.show(SubscriptionSelectionModal, { storeId: this.store.id }, SubscriptionSelectionModal.modalProps)
    },
    showDeleteFeedModal(feed) {
      if (feed.active === true) return

      this.$modal.show(DeleteFeedModal, {
        feed,
        onDeleted: () => this.loadFeeds(this.store.id),
      })
    },
    async feedHasErrors(feedId) {
      try {
        const res = await this.$http.get(`/feed/${feedId}/errors`)
        this.feedErrorList = res.data.data
        return this.feedErrorList
      } catch (err) {
        this.feedErrorList = null
      }
    },
    reactivateFeed(feedId) {
      this.createError = null

      return this.$http
        .put(`/feed/${feedId}/check/trigger`)
        .then(() => {
          this.$toast.success({
            component: Toast,
            props: {
              title: 'Success',
              message: 'Feed activated!',
              type: 'success',
            },
          })
        })
        .catch(e => {
          this.createError = e
          this.$toast.error({
            component: Toast,
            props: {
              title: 'Error',
              message: this.createError.response.data.error,
              type: 'error',
            },
          })
        })
    },
    readableDate(unreadableDate) {
      if (unreadableDate) {
        return dayjs(unreadableDate).format('YYYY-MM-DD')
      } else {
        return 'N/A'
      }
    },
  },
  beforeMount() {
    if (this.store) this.loadFeedsIfNeeded(this.store.id)
  },
}
</script>
<style lang="scss" scoped>
table {
  @apply w-full;
}

th {
  @apply text-left;
}

td,
th {
  @apply p-4 border;
}
</style>
