<template>
  <base-modal>
    <template #header>
      <div class="mt-10 md:-mt-2 mx-auto">
        <h1 class="mb-2 lg:w-3/6 mx-auto">Link Bidbrain supplemental feed to Google Merchant Center</h1>
        <p class="p2">
          Here’s our quick walkthrough on how to add a supplemental feed in Google Merchant Center. You will need this
          feed in order for Bidbrain to function properly.
        </p>
        <div class="absolute top-0 right-0 p-10">
          <div
            @click="$emit('close')"
            class="select-none cursor-pointer"
          >
            Close X
          </div>
        </div>
      </div>
    </template>
    <template #default>
      <supplemental-feed-guide />
    </template>
    <template #footer>
      <div class="flex flex-row justify-center">
        <the-button
          @click="$emit('close')"
          primary
          medium
        >
          OK
        </the-button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import SupplementalFeedGuide from '@/components/feed/SupplementalFeedGuide'
import IcDone from '../icon/ic-done.vue'

export default {
  name: 'supplemental-feed-guide-modal',
  components: { SupplementalFeedGuide, IcDone },
  modalProps: {
    height: 'auto',
    maxWidth: 850,
    scrollable: true,
  },
}
</script>
