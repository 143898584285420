<template>
  <base-modal>
    <template #header>
      <h3 class="m-0">Add a feed</h3>
    </template>
    <template #default>
      <feed-form @submitted="$emit('close')">
        <template #action-btn="{ clear, loading, countryId, submit }">
          <div class="flex space-x-4 justify-end mt-8">
            <the-button
              @click="clear"
              :disabled="loading"
              secondary
            >
              Clear
            </the-button>
            <the-button
              @click="submit"
              :disabled="loading || !countryId"
              primary
            >
              Add
            </the-button>
          </div>
        </template>
      </feed-form>
    </template>
  </base-modal>
</template>

<script>
import FeedForm from '@/components/feed/FeedForm'

export default {
  name: 'create-feed-modal',
  components: { FeedForm },
}
</script>
