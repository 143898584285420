<template>
  <page v-if="proStore && !account.sales_led && !mainSteps && !feeds.length">
    <page-section bottom-border>
      <page-title>
        <template #icon>
          <ic-checklist :size="32" />
        </template>
        Campaign feeds
      </page-title>
    </page-section>
    <page-section bottom-border>
      <base-alert type="information">
        <template #title> Information </template>
        <template #content> Complete the onboarding to get started with Bidbrain </template>
        <template #action>
          <the-button
            class="my-auto"
            @click="$router.push({ name: 'dashboard' })"
            information
          >
            Complete onboarding
            <template #icon>
              <ic-chevron-right :size="16" />
            </template>
          </the-button>
        </template>
      </base-alert>
    </page-section>
  </page>
  <page v-else>
    <page-section bottom-border>
      <page-title>
        <template #icon>
          <ic-checklist :size="32" />
        </template>
        Campaign feeds
      </page-title>
    </page-section>
    <page-section v-if="feeds && !feeds.length">
      <hero>
        <template #image>
          <img
            src="/content/images/illustrations/empty.png"
            alt="no-feeds"
          />
        </template>
        <template #title> There’s nothing here! </template>
        <template #description> You don’t have any feeds yet, add one to get started. </template>
        <template #buttons>
          <the-button
            @click="showFeedForm"
            secondary
            medium
          >
            <template #icon>
              <ic-plus />
            </template>
            <template> Add a feed </template>
          </the-button>
        </template>
      </hero>
    </page-section>
    <page-section v-else>
      <base-alert
        v-if="showGuideAlert"
        type="information"
      >
        <template #title> Get started! </template>
        <template #content>
          Connect Bidbrain to your Google Merchant Center account to enable feed optimisation features
        </template>
        <template #action>
          <the-button
            @click="showSupplementalFeedGuide"
            information
          >
            <template #default> Setup connection to GMC </template>
            <template #icon>
              <ic-plus :size="18" />
            </template>
          </the-button>
        </template>
      </base-alert>
      <feed-list />
    </page-section>
  </page>
</template>

<script>
import IcChecklist from '@/components/icon/ic-checklist'
import IcPlus from 'vue-material-design-icons/Plus'
import FeedList from '@/components/feed/FeedList'
import BaseAlert from '@/components/alert/BaseAlert'
import SupplementalFeedGuideModal from '@/components/modals/SupplementalFeedGuideModal'
import IcChevronRight from 'vue-material-design-icons/ChevronRight'
import { mapActions, mapGetters } from 'vuex'
import { hasBeenLive } from '@/core/store'
import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection.vue'
import Hero from '@/components/shared/Hero.vue'
import CreateFeedModal from '@/components/modals/CreateFeedModal.vue'

export default {
  name: 'feed',
  components: {
    Hero,
    PageSection,
    Page,
    IcChecklist,
    FeedList,
    BaseAlert,
    SupplementalFeedGuideModal,
    IcPlus,
    IcChevronRight,
  },
  computed: {
    ...mapGetters({
      store: 'store/selectedStore',
      feedsByStoreId: 'feed/getFeedsByStoreId',
      feedsLoadingByStoreId: 'feed/isFeedsLoadingByStoreId',
      lastCheckByFeedId: 'check/lastByFeedId',
      account: 'account/getAccount',
      steps: 'onBoardingForm/steps',
      mainSteps: 'onBoardingForm/mainStepCompleted',
    }),
    proStore() {
      if (this.store.subscription_slug == 'pro' || this.store.subscription_slug == 'pro_plus') {
        return true
      }
    },

    feeds() {
      if (!this.store) return []
      return this.feedsByStoreId(this.store.id)
    },

    loadingFeeds() {
      if (!this.store) return false
      return this.feedsLoadingByStoreId(this.store.id)
    },
    showGuideAlert() {
      return (
        this.feeds.length > 0 &&
        !hasBeenLive(this.store) &&
        (this.store.subscription_slug === 'pro' || this.store.subscription_slug === 'pro_plus')
      )
    },
  },
  watch: {
    store(store) {
      if (store) this.loadFeedsByStoreIfNeeded(store.id)
    },
  },
  methods: {
    ...mapActions({
      loadFeedsByStoreIfNeeded: 'feed/loadFeedsByStoreIdIfNeeded',
    }),
    showSupplementalFeedGuide() {
      this.$modal.show(SupplementalFeedGuideModal, null, SupplementalFeedGuideModal.modalProps)
    },
    showFeedForm() {
      this.$modal.show(CreateFeedModal)
    },
  },
  beforeMount() {
    if (this.store) this.loadFeedsByStoreIfNeeded(this.store.id)
  },
}
</script>
