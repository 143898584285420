<template>
  <div class="md:border md:p-4 -mx-4">
    <bb-loader v-if="loadingLastCheck" />
    <div v-else-if="!lastCheck">No feed check</div>
    <table
      v-else-if="lastCheck.feed_check_items.length > 0"
      class="hidden md:table w-full"
    >
      <caption class="hidden">
        Feed health check
      </caption>
      <tr class="font-semibold text-left">
        <th scope="col">Level</th>
        <th scope="col">Check / Issue</th>
        <th scope="col">Result</th>
        <th scope="col">Change</th>
      </tr>
      <tbody>
        <tr
          v-for="item in lastCheck.feed_check_items"
          :key="`check-${item.id}`"
        >
          <td class="py-3 capitalize">{{ item.level }}</td>
          <td>{{ item.name }}</td>
          <td
            :class="{
              'cursor-pointer text-blue-500 underline': item.extended_data_href,
            }"
            @click="showExtendedData(item.extended_data_href, item.name)"
          >
            {{ item.result.latest.value }}
          </td>
          <td
            :class="{
              'text-orange-500': item.diff == 'Changed',
              'text-bb-mid-grey': item.diff == 'No change' || item.diff == 0,
            }"
          >
            <span class="flex flex-row gap-2">
              <p class="my-auto">
                {{ item.diff }}
              </p>
              <ic-quality-indicator
                v-if="item.diff < 0 || item.diff > 0"
                :size="24"
                :direction="item.diff > 0 ? 'up' : 'down'"
                :class="{
                  'text-red-600': item.diff < 0,
                  'text-bb-neon-green': item.diff > 0,
                }"
              />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="md:hidden space-y-2"
      v-if="lastCheck && lastCheck.feed_check_items.length > 0"
    >
      <div
        v-for="item in lastCheck.feed_check_items"
        :key="`check-item-${item.id}`"
        class="grid grid-cols-2 border p-2 rounded"
      >
        <p class="font-semibold">Level</p>
        <p class="capitalize">{{ item.level }}</p>
        <p class="font-semibold">Check / Issue</p>
        <p>{{ item.name }}</p>
        <p class="font-semibold">Result</p>
        <p
          :class="{
            'cursor-pointer text-blue-500 underline': item.extended_data_href,
          }"
          @click="showExtendedData(item.extended_data_href, item.name)"
        >
          {{ item.result.latest.value }}
        </p>
        <p class="font-semibold">Change</p>
        <span class="flex flex-row gap-2">
          <p>
            {{ item.diff }}
          </p>
          <ic-quality-indicator
            v-if="item.diff < 0 || item.diff > 0"
            :size="24"
            :direction="item.diff > 0 ? 'up' : 'down'"
            class="mb-4"
            :class="{
              'text-red-600': item.diff < 0,
              'text-bb-neon-green': item.diff > 0,
            }"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import IcQualityIndicator from '@/components/icon/ic-quality-indicator'
import CheckItemExtendedData from './CheckItemExtendedData'
import BbLoader from '@/components/loader/BBLoader'

export default {
  name: 'feed-checker',

  components: { BbLoader, IcQualityIndicator, CheckItemExtendedData },

  props: {
    feedId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      latest: null,
      displayExtendedData: false,
      extendedDataUrl: null,
      extendedDataTitle: null,
      queueingCheck: false,
      queueingError: null,
    }
  },
  mounted() {
    this.loadLastIfNeeded(this.feedId)
    this.loadPendingIfNeeded(this.feedId)
  },
  computed: {
    ...mapGetters({
      lastCheckByFeedId: 'check/lastByFeedId',
      loadingLastCheckByFeedId: 'check/loadingLastByFeedId',
      errorLastCheckByFeedId: 'check/errorLastByFeedId',
      pendingCheckByFeedId: 'check/pendingByFeedId',
      loadingPendingCheckByFeedId: 'check/loadingPendingByFeedId',
      errorPendingCheckByFeedId: 'check/errorPendingByFeedId',
    }),
    lastCheck() {
      return this.lastCheckByFeedId(this.feedId)
    },
    lastCheckText() {
      if (!this.lastCheck) {
        return 'None'
      }
      if (!!this.lastCheck && !this.lastCheck.finished_at) {
        return 'UNKNOWN TIME'
      }
      return this.$dayjs(this.lastCheck.finished_at).format('YYYY-MM-DD hh:mm (dddd)')
    },
    loadingLastCheck() {
      return this.loadingLastCheckByFeedId(this.feedId)
    },
    errorLastCheck() {
      return this.errorLastCheckByFeedId(this.feedId)
    },
    pendingCheck() {
      return this.pendingCheckByFeedId(this.feedId)
    },
    loadingPendingCheck() {
      return this.loadingPendingCheckByFeedId(this.feedId)
    },
    errorPendingCheck() {
      return this.errorPendingCheckByFeedId(this.feedId)
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      loadLastIfNeeded: 'check/loadLastIfNeeded',
      loadLastByFeedId: 'check/loadLastByFeedId',
      loadPendingIfNeeded: 'check/loadPendingIfNeeded',
      loadPendingByFeedId: 'check/loadPendingByFeedId',
    }),
    refreshData() {
      this.loadLastByFeedId(this.feedId)
      this.loadPendingByFeedId(this.feedId)
    },
    queueFeedCheck() {
      if (!!this.pendingCheck) {
        return
      }

      this.queueingCheck = true
      this.queueingError = null

      this.$axios
        .$put(`/feed/${this.feedId}/check/trigger`)
        .then(this.refreshData)
        .catch(e => (this.queueingError = e))
        .finally(() => (this.queueingCheck = false))
    },
    showExtendedData(url, name) {
      if (url) {
        this.$modal.show(CheckItemExtendedData, { url, name }, CheckItemExtendedData.modalProps)
      }
    },
  },
}
</script>
