<template>
  <base-modal>
    <template #header>
      <h2>Delete feed</h2>
    </template>
    <template #default>
      Do you really want to delete feed <span class="font-bold">"{{ feed.name }}"</span>?
    </template>
    <template #footer>
      <div class="flex space-x-4 justify-end">
        <the-button
          @click="$emit('close')"
          :disabled="deleting"
          secondary
        >
          Cancel
        </the-button>
        <the-button
          @click="deleteFeed"
          primary
          >Delete
        </the-button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'delete-feed-modal',
  props: {
    feed: {
      type: Object,
      required: true,
    },
    onDeleted: {
      type: Function,
      required: true,
    },
  },
  emits: ['feed-deleted'],
  data() {
    return {
      deleting: false,
    }
  },
  methods: {
    ...mapActions({
      loadFeedsByStoreIdIfNeeded: 'feed/loadFeedsByStoreIdIfNeeded',
    }),
    async deleteFeed() {
      this.deleting = true
      await this.$http.delete(`/store/${this.feed.store_id}/feed/${this.feed.id}`)
      await this.loadFeedsByStoreIdIfNeeded(this.feed.store_id)
      this.$emit('feed-deleted', this.feed)
      this.deleting = false
      this.onDeleted(this.feed)
      this.$emit('close')
      this.$toast.success({
        component: Toast,
        props: {
          title: 'Success',
          message: 'Feed deleted successfully!',
          type: 'success',
        },
      })
    },
  },
}
</script>
