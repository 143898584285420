var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"space-y-8 p2"},[_c('div',[_c('h3',[_vm._v("Logging on:")]),_c('ol',[_c('li',[_vm._v(" Log into your Merchant Center account "),_c('a',{staticClass:"ml-2",attrs:{"href":"https://merchants.google.com/","target":"_blank","rel":"noopener"}},[_c('ic-open-window')],1)]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)])]),_c('div',[_c('h3',[_vm._v("Creating a feed:")]),_c('ol',[_vm._m(5),_vm._m(6),_c('li',[_vm._v(" Select a time that the supplemental feed will be fetched each day under “Fetch time”. It’s recommended to select a time 1-2 hours after the primary feed usually gets updated. ")]),_c('li',[_c('div',{staticClass:"w-full"},[_vm._v(" Enter the following file url: "),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"bg-bb-pale-grey p-3 overflow-x-auto"},[_vm._v(" "+_vm._s(_vm.supplementalFeedAddress)+" ")]),_c('div',{staticClass:"flex items-center link-primary no-underline ml-4",on:{"click":_vm.copy}},[_c(_vm.copyIcon,{tag:"component",staticClass:"mr-2"}),_vm._v(" Copy ")],1)])])]),_vm._m(7)])]),_vm._m(8)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("On the left side menu click on "),_c('span',{staticClass:"text-bb-neon-purple"},[_vm._v("‘Products’")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("Click on "),_c('span',{staticClass:"text-bb-neon-purple"},[_vm._v(" ‘Feeds’")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("Click on "),_c('span',{staticClass:"text-bb-neon-purple"},[_vm._v("‘Add supplemental feed’")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v(" Enter your store name and country for the supplemental feed. It must be unique. "),_c('br'),_vm._v(" ex: ‘Bidbrain MyStoreName SE supplemental feed’ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("Select "),_c('span',{staticClass:"text-bb-neon-purple"},[_vm._v("‘Scheduled fetch’")]),_vm._v("and continue")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v(" Enter a file name for the supplemental feed, we recommend using the same name as before "),_c('br'),_vm._v("ex: ‘Bidbrain MyStoreName SE supplemental feed’ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("Under Fetch frequency, select "),_c('span',{staticClass:"text-bb-neon-purple"},[_vm._v("‘Daily’")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("Leave the "),_c('span',{staticClass:"text-bb-neon-purple"},[_vm._v("Password")]),_vm._v(" blank, and continue")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("Adding to primary feed:")]),_c('ol',[_c('li',[_vm._v("Select the primary feed that you want to connect the supplemental feed to")]),_c('li',[_vm._v("Select "),_c('span',{staticClass:"text-bb-neon-purple"},[_vm._v("‘Create feed’")])])])])
}]

export { render, staticRenderFns }