<template>
  <base-filter-picker
    :label="label || 'Language'"
    :placeholder="placeholder || 'Select language'"
    :value="value"
    @input="val => $emit('input', val)"
    @focus="$emit('focus', $event)"
    @focusout="$emit('focusout', $event)"
    :items="languages"
    :item-label="item => item.name"
    :item-value="item => item"
    :filter="(item, val) => item && item.name && val && item.name.toLowerCase().includes(val.toLowerCase())"
    :loading="loading"
    :success="success"
    :disabled="disabled"
    :error="error"
    :error-message="errorMessage"
    :input-name="inputName"
    :input-id="inputId"
  >
  </base-filter-picker>
</template>

<script>
import BaseFilterPicker from '@/components/input/base/BaseFilterPicker'
export default {
  name: 'language-picker',
  components: { BaseFilterPicker },
  props: [
    'value',
    'languages',
    'disabled',
    'label',
    'loading',
    'error',
    'errorMessage',
    'success',
    'inputName',
    'inputId',
    'placeholder',
  ],
  emits: ['input', 'blur'],
}
</script>
