<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 7.00005H13V9.00005H22V7.00005ZM22 15.0001H13V17.0001H22V15.0001ZM5.54 11.0001L2 7.46005L3.41 6.05005L5.53 8.17005L9.77 3.93005L11.18 5.34005L5.54 11.0001ZM5.54 19.0001L2 15.4601L3.41 14.0501L5.53 16.1701L9.77 11.9301L11.18 13.3401L5.54 19.0001Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'ic-checklist',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
    title: {
      type: String,
      default: 'Checklist',
    },
  },
}
</script>
