var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:border md:p-4 -mx-4"},[(_vm.loadingLastCheck)?_c('bb-loader'):(!_vm.lastCheck)?_c('div',[_vm._v("No feed check")]):(_vm.lastCheck.feed_check_items.length > 0)?_c('table',{staticClass:"hidden md:table w-full"},[_c('caption',{staticClass:"hidden"},[_vm._v(" Feed health check ")]),_c('tr',{staticClass:"font-semibold text-left"},[_c('th',{attrs:{"scope":"col"}},[_vm._v("Level")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Check / Issue")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Result")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Change")])]),_c('tbody',_vm._l((_vm.lastCheck.feed_check_items),function(item){return _c('tr',{key:`check-${item.id}`},[_c('td',{staticClass:"py-3 capitalize"},[_vm._v(_vm._s(item.level))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',{class:{
            'cursor-pointer text-blue-500 underline': item.extended_data_href,
          },on:{"click":function($event){return _vm.showExtendedData(item.extended_data_href, item.name)}}},[_vm._v(" "+_vm._s(item.result.latest.value)+" ")]),_c('td',{class:{
            'text-orange-500': item.diff == 'Changed',
            'text-bb-mid-grey': item.diff == 'No change' || item.diff == 0,
          }},[_c('span',{staticClass:"flex flex-row gap-2"},[_c('p',{staticClass:"my-auto"},[_vm._v(" "+_vm._s(item.diff)+" ")]),(item.diff < 0 || item.diff > 0)?_c('ic-quality-indicator',{class:{
                'text-red-600': item.diff < 0,
                'text-bb-neon-green': item.diff > 0,
              },attrs:{"size":24,"direction":item.diff > 0 ? 'up' : 'down'}}):_vm._e()],1)])])}),0)]):_vm._e(),(_vm.lastCheck && _vm.lastCheck.feed_check_items.length > 0)?_c('div',{staticClass:"md:hidden space-y-2"},_vm._l((_vm.lastCheck.feed_check_items),function(item){return _c('div',{key:`check-item-${item.id}`,staticClass:"grid grid-cols-2 border p-2 rounded"},[_c('p',{staticClass:"font-semibold"},[_vm._v("Level")]),_c('p',{staticClass:"capitalize"},[_vm._v(_vm._s(item.level))]),_c('p',{staticClass:"font-semibold"},[_vm._v("Check / Issue")]),_c('p',[_vm._v(_vm._s(item.name))]),_c('p',{staticClass:"font-semibold"},[_vm._v("Result")]),_c('p',{class:{
          'cursor-pointer text-blue-500 underline': item.extended_data_href,
        },on:{"click":function($event){return _vm.showExtendedData(item.extended_data_href, item.name)}}},[_vm._v(" "+_vm._s(item.result.latest.value)+" ")]),_c('p',{staticClass:"font-semibold"},[_vm._v("Change")]),_c('span',{staticClass:"flex flex-row gap-2"},[_c('p',[_vm._v(" "+_vm._s(item.diff)+" ")]),(item.diff < 0 || item.diff > 0)?_c('ic-quality-indicator',{staticClass:"mb-4",class:{
            'text-red-600': item.diff < 0,
            'text-bb-neon-green': item.diff > 0,
          },attrs:{"size":24,"direction":item.diff > 0 ? 'up' : 'down'}}):_vm._e()],1)])}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }