export const hasBeenLive = store => !!store && store.logs && store.logs.store_live && store.logs.store_live.length > 0

export function addDashesToGoogleAccountId(accountId) {
  // Remove any existing dashes and non-digit characters
  accountId = accountId.replace(/-/g, '').replace(/\D/g, '')

  // Define the pattern for adding dashes (3 digits, dash, 3 digits, dash, 3 digits)
  const pattern = /^(\d{3})(\d{3})(\d{4})$/

  // Execute the pattern and insert dashes accordingly
  return accountId.replace(pattern, '$1-$2-$3')
}
