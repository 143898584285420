import { render, staticRenderFns } from "./FeedList.vue?vue&type=template&id=77c5c6c3&scoped=true"
import script from "./FeedList.vue?vue&type=script&lang=js"
export * from "./FeedList.vue?vue&type=script&lang=js"
import style0 from "./FeedList.vue?vue&type=style&index=0&id=77c5c6c3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77c5c6c3",
  null
  
)

export default component.exports