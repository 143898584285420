<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Bin</title>
    <path
      d="M1.5 18H13.5V4H1.5V18ZM3.96 8.88L5.37 7.47L7.5 9.59L9.62 7.47L11.03 8.88L8.91 11L11.03 13.12L9.62 14.53L7.5 12.41L5.38 14.53L3.97 13.12L6.09 11L3.96 8.88ZM11 1L10 0H5L4 1H0.5V3H14.5V1H11Z"
      fill="#3D3A5C"
    />
  </svg>
</template>
<script>
export default {
  name: 'ic-bin',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
